<template>
  <div
    class="welcome d-flex justify-content-center text-center align-items-center"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <img
            src="@/assets/img/logo.png"
            alt="Logo"
            width="150"
            height="150"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-white text-welcome my-4">
            Selamat datang di website resmi
            <span class="font-weight-bold d-block">{{ nama_sekolah }}</span>
          </p>
          <blockquote class="text-white">" {{ deskripsi }} "</blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: ["nama_sekolah", "deskripsi"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";
.welcome {
  height: 100vh;
  background-color: $primaryColor;

  .text-welcome {
    font-family: "Maven Pro";
    font-size: 1.5em;
    @media (min-width: 768px) {
      font-size: 2em;
    }
    font-weight: 400;
    span {
      font-weight: 600;
    }
  }
  blockquote {
    @media (min-width: 768px) {
      font-size: 1.2em;
      padding: 0 20%;
    }
    line-height: 1.8em;
  }
}
</style>
